export const riverProfitShips = [
  {
    nameProfitShip: 'Волга Стар',
    linkProfitShip: '/ships/volgastar',
  },
  {
    nameProfitShip: 'Иван Бунин',
    linkProfitShip: '/ships/ivan-bunin',
  },
  {
    nameProfitShip: 'Княжна Анастасия',
    linkProfitShip: '/ships/knyazhna-anactaciya',
  },
  {
    nameProfitShip: 'Леонид Красин',
    linkProfitShip: '/ships/leonid-kracin',
  },
  {
    nameProfitShip: 'Сергей Кучкин',
    linkProfitShip: '/ships/cergey-kuchkin',
  },
];

export const riverBestReviewsShips = [
  {
    nameBestRevShip: 'Александр Грин',
    linkBestRevShip: '/ships/alekcandr-grin',
  },
  {
    nameBestRevShip: 'Андрей Рублев',
    linkBestRevShip: '/ships/andrey-rublev',
  },

  {
    nameBestRevShip: 'Лебединое озеро',
    linkBestRevShip: '/ships/lebedinoe-ozero',
  },
  {
    nameBestRevShip: 'Ленин',
    linkBestRevShip: '/ships/lenin',
  },
  {
    nameBestRevShip: 'Леонид Красин',
    linkBestRevShip: '/ships/leonid-kracin',
  },
  {
    nameBestRevShip: 'Максим Горький',
    linkBestRevShip: '/ships/makcim-gor-kiy',
  },
  {
    nameBestRevShip: 'Маленький принц',
    linkBestRevShip: '/ships/malenkiy_printz',
  },
  {
    nameBestRevShip: 'Мустай Карим',
    linkBestRevShip: '/ships/mustai-karim',
  },
  {
    nameBestRevShip: 'Россия',
    linkBestRevShip: '/ships/rossiya',
  },
  {
    nameBestRevShip: 'Юрий Никулин',
    linkBestRevShip: '/ships/yury-nikulin',
  },
];

export const seaProfitShips = [
  {
    nameProfitShip: 'Astoria Grande',
    linkProfitShip: '/ships/astoria-grande',
  },
  {
    nameProfitShip: 'Celestyal Journey',
    linkProfitShip: '/ships/celestyal-journey',
  },
  {
    nameProfitShip: 'Celestyal Discovery',
    linkProfitShip: '/ships/celestyal-discovery',
  },
  {
    nameProfitShip: 'MSC Divina',
    linkProfitShip: '/ships/msc-divina-',
  },
  {
    nameProfitShip: 'MSC Sinfonia',
    linkProfitShip: '/ships/msc-sinfonia',
  },
];

export const seaBestReviewsShips = [
  {
    nameBestRevShip: 'MSC Euribia',
    linkBestRevShip: '/ships/msc-euribia',
  },
  {
    nameBestRevShip: 'Spectrum Of The Seas',
    linkBestRevShip: '/ships/spectrum-of-the-seas',
  },
  {
    nameBestRevShip: 'MSC FANTASIA',
    linkBestRevShip: '/ships/msc-fantasia',
  },
  {
    nameBestRevShip: 'MSC Musica',
    linkBestRevShip: '/ships/msc-musica',
  },
  {
    nameBestRevShip: 'MSC Grandiosa',
    linkBestRevShip: '/ships/msc-grandiosa',
  },
  {
    nameBestRevShip: 'MSC Splendida',
    linkBestRevShip: '/ships/msc-splendida',
  },
  {
    nameBestRevShip: 'Celebrity Millennium',
    linkBestRevShip: '/ships/celebrity-millennium',
  },
  {
    nameBestRevShip: 'Anthem Of The Seas',
    linkBestRevShip: '/ships/anthem-of-the-seas',
  },
  {
    nameBestRevShip: 'MSC Seaside',
    linkBestRevShip: '/ships/msc-seaside',
  },
  // {
  //   nameBestRevShip: 'Enchanted Princess',
  //   linkBestRevShip: '',
  // },
  // {
  //   nameBestRevShip: 'Majestic Princess',
  //   linkBestRevShip: '',
  // },
];
